.table-header {
    display: flex;
    justify-content: space-between;
}

.p-multiselect {
    min-width: 15rem;
}

.divider {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: rgb(233, 236, 239);

}